import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";
// import { currentUser, logout } from "../_services/authentication.service";

const Header = () => (
  <header className="p-3">
    <div className="container">
      <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <a
          href="/"
          className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none"
        >
          <img
            src="https://www.superbase.com/wp-content/uploads/2020/10/SuperBase-Alpha.png"
            alt="Superbase Logo"
            className="img-fluid"
            width="120"
            height="70"
          />
        </a>

        <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
          <ul
            className="nav-link px-2 text-dark dropdown-toggle"
            id="productDropdown"
            data-bs-toggle="dropdown"
          >
            Products
          </ul>
          <ul
            className="dropdown-menu text-small"
            aria-labelledby="productDropdown"
            data-popper-placement="bottom-start"
          >
            {/* style="{position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate(0px, 34px);" */}
            <Link to="/products/superbase-ng-ide" className="dropdown-item">
              Superbase NG Professional
            </Link>
            <Link
              to="/products/superbase-ng-personal"
              className="dropdown-item"
            >
              Superbase NG Personal
            </Link>
            <Link to="/products/server" className="dropdown-item">
              Superbase Multi-User <br></br>
              Database Engine
            </Link>
            <hr className="dropdown-divider" />
            <Link to="/products/legacy" className="dropdown-item">
              Legacy Products
            </Link>
            <hr className="dropdown-divider" />
            <Link to="/products/repair-portal" className="dropdown-item">
              Superbase x DKAVS Repair Portal
            </Link>
            <Link to="/products/web-shop" className="dropdown-item">
              Superbase Web Shop
            </Link>
            <hr className="dropdown-divider" />
            <a
              href="https://www.superbase.com/downloads/"
              className="dropdown-item"
            >
              Downloads
            </a>
          </ul>
          <a
            href="https://www.superbase.com/news/"
            className="nav-link px-2 text-dark"
          >
            Blog
          </a>
          <a
            href="https://www.superbase.com/services/"
            className="nav-link px-2 text-dark"
          >
            Services
          </a>
          <a
            href="https://www.superbase.com/about/"
            className="nav-link px-2 text-dark"
          >
            About Us
          </a>
          <a
            href="https://www.superbase.com/contact/"
            className="nav-link px-2 text-dark"
          >
            Contact Us
          </a>
        </ul>

        <div className="text-end">
          <div className="dropdown text-end">
            <Link to="/account" className="btn mr-2">
              Login
            </Link>

            <Link to="/account" className="btn btn-warning">
              Register
            </Link>
          </div>
        </div>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
