/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./Header";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQueryQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header />
      <main>{children}</main>
      <footer className="footer mt-auto py-3 bg-dark text-white text-center">
        <p>
          © 2021 Superbase Software Ltd. ·{" "}
          <a href="https://www.superbase.com/privacy/">Privacy</a> ·{" "}
          <a href="https://www.simpol.com/terms-conditions/">Terms</a> ·{" "}
          <a href="https://www.superbase.com/contact/">Contact Us</a>
        </p>
      </footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
